import { useState, useCallback } from "react";
import {
  Button,
  Stepper,
  StepperProps,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { merge } from "lodash";
import { lighten } from "polished";

const defaultStyles = {
  stepBody: { display: "none" },
  step: { padding: 0 },
  stepIcon: { borderWidth: 4 },
  separator: {
    marginLeft: -2,
    marginRight: -2,
    height: 3,
    backgroundColor: "#c8a88f",
  },
};

const defaultButtonStyles = {
  borderRadius: 100,
  borderWidth: 2,
  height: 49,
  boxShadow: "inset 0 0 10px rgba(255, 255, 255, 0.2)",
};

const getButtonStyles = (
  active: boolean,
  customStyles: React.CSSProperties,
  primaryColor: string,
  isLightTheme: boolean
) => ({
  ...customStyles,
  borderColor: active ? primaryColor : isLightTheme ? "white" : "white",
  backgroundColor: active ? lighten(0.45, primaryColor) : "#f7f7f7",
  color: active ? primaryColor : isLightTheme ? "black" : "#FFF",
  boxShadow: active ? "none" : customStyles.boxShadow,
});

const StyledStepper = (props: StepperProps) => {
  const mergedStyles = merge({}, defaultStyles, props.styles);
  return <Stepper {...props} styles={mergedStyles} />;
};

interface Step {
  label: string;
  description: string;
}

interface ApplicationProgressStepperProps {
  active: number;
  handleStepClick: (index: number) => void;
  steps: Step[];
  buttonStyles?: React.CSSProperties;
  primaryColor?: string;
  isLightTheme?: boolean;
}

export const ApplicationProgressStepper = ({
  active,
  handleStepClick,
  steps,
  buttonStyles = {},
  primaryColor = "#c8a88f",
  isLightTheme = true,
}: ApplicationProgressStepperProps) => {
  const mergedButtonStyles = merge({}, defaultButtonStyles, buttonStyles);
  const theme = useMantineTheme();
  const primary = theme.colors[theme.primaryColor][6];

  return (
    <StyledStepper
      active={active}
      onStepClick={handleStepClick}
      styles={{ separator: { backgroundColor: primary } }}
    >
      {steps?.map((step, index) => (
        <Stepper.Step
          key={index}
          label={step.label}
          renderRoot={() => (
            <Tooltip label={step.description}>
              <Button
                onClick={() => handleStepClick(index)}
                style={getButtonStyles(
                  active === index,
                  mergedButtonStyles,
                  primary,
                  isLightTheme
                )}
              >
                {step.label}
              </Button>
            </Tooltip>
          )}
        />
      ))}
    </StyledStepper>
  );
};
