import React, { useEffect, useMemo, useReducer } from "react";
import { useSearchParams } from "react-router-dom";
import { ICountry } from "../types/visaRequirements.type";
import { IApplication } from "../types/application.types";
import applicationService from "../services/application.service";
import { DateValue } from "@mantine/dates";
import documentService from "../services/document.service";
import { IDocument, IDocumentData } from "../types/document.types";
import { useUser } from "./UserContext";

interface IApplicationContextProps {
  activeStep: number;
  fromCountry: ICountry;
  toCountry: ICountry;
  toDate: Date | null;
  fromDate: Date | null;
  category: string | null;
  nationality: ICountry;
  applicantUid: string;
  application: IApplication | null;
  setActiveStep: (index: number) => void;
  setFromDate: (fromDate: DateValue) => void;
  setToDate: (toDate: DateValue) => void;
  setCategory: (category: string) => void;
  setFromCountry: (fromCountry: ICountry) => void;
  setNationality: (nationality: ICountry) => void;
  setToCountry: (toCountry: ICountry) => void;
  documents: IDocumentData[];
}

interface IApplicationContextState {
  activeStep: number;
  fromCountry: ICountry;
  toCountry: ICountry;
  toDate: Date | null;
  fromDate: Date | null;
  category: string | null;
  nationality: ICountry;
  application: IApplication | null;
  applicantUid: string;
  documents: IDocumentData[];
}

type IApplicationContextStateActions =
  | {
      type: "active-step";
      activeStep: number;
    }
  | {
      type: "application";
      application: IApplication | null;
    }
  | {
      type: "fromDate";
      fromDate: Date | null;
    }
  | {
      type: "toDate";
      toDate: Date | null;
    }
  | {
      type: "category";
      category: string | null;
    }
  | {
      type: "nationality";
      nationality: ICountry;
    }
  | {
      type: "fromCountry";
      fromCountry: ICountry;
    }
  | {
      type: "documents";
      documents: IDocumentData[];
    }
  | {
      type: "toCountry";
      toCountry: ICountry;
    };

const steps = [
  "/get-visa",
  "/visa-requirements",
  "/fileUpload",
  "/document-playground",
  "/form",
];

const ApplicationContextStateReducer: React.Reducer<
  IApplicationContextState,
  IApplicationContextStateActions
> = (state, action) => {
  switch (action.type) {
    case "active-step":
      return { ...state, activeStep: action.activeStep };
    case "application":
      return { ...state, application: action.application };
    case "fromDate":
      return { ...state, fromDate: action.fromDate };
    case "toDate":
      return { ...state, toDate: action.toDate };
    case "category":
      return { ...state, category: action.category };
    case "nationality":
      return { ...state, nationality: action.nationality };
    case "fromCountry":
      return { ...state, fromCountry: action.fromCountry };
    case "toCountry":
      return { ...state, toCountry: action.toCountry };
    case "documents":
      return { ...state, documents: action.documents };
    default:
      return state;
  }
};

const ApplicationContext = React.createContext<
  Partial<IApplicationContextProps>
>({});

export const ApplicationProvider = (props: any) => {
  const ApplicationState: IApplicationContextState = {
    activeStep: -1,
    fromCountry: { country: "", countryISO: "" },
    toCountry: { country: "", countryISO: "" },
    toDate: null,
    fromDate: null,
    category: null,
    nationality: { country: "", countryISO: "" },
    applicantUid: "",
    application: null,
    documents: [],
  };

  const [state, dispatch] = useReducer(
    ApplicationContextStateReducer,
    ApplicationState
  );

  const [searchParams] = useSearchParams();

  const memoizedSearchParams = useMemo(
    () => searchParams.toString(),
    [searchParams]
  );

  const applicationUid =
    new URLSearchParams(memoizedSearchParams).get("applicationUid") || null;
  const docIdsString =
    new URLSearchParams(memoizedSearchParams).get("docIds") || null;
  const applicantUid =
    new URLSearchParams(memoizedSearchParams).get("applicantUid") || null;
  const category =
    new URLSearchParams(memoizedSearchParams).get("category") || null;
  const fromDate =
    new URLSearchParams(memoizedSearchParams).get("fromDate") || null;
  const toDate =
    new URLSearchParams(memoizedSearchParams).get("toDate") || null;

  const fromCountry = JSON.parse(
    decodeURIComponent(
      new URLSearchParams(memoizedSearchParams).get("fromCountry")!
    )
  );

  const toCountry = JSON.parse(
    decodeURIComponent(
      new URLSearchParams(memoizedSearchParams).get("toCountry")!
    )
  );

  const nationality = JSON.parse(
    decodeURIComponent(
      new URLSearchParams(memoizedSearchParams).get("nationality")!
    )
  );

  const setActiveStep = (activeStep: number) =>
    dispatch({ type: "active-step", activeStep });
  const setCategory = (category: string) =>
    dispatch({ type: "category", category });
  const setFromDate = (fromDate: Date) =>
    dispatch({ type: "fromDate", fromDate });
  const setToDate = (toDate: Date) => dispatch({ type: "toDate", toDate });
  const setFromCountry = (fromCountry: ICountry) =>
    dispatch({ type: "fromCountry", fromCountry });
  const setNationality = (nationality: ICountry) =>
    dispatch({ type: "nationality", nationality });
  const setToCountry = (toCountry: ICountry) =>
    dispatch({ type: "toCountry", toCountry });

  useEffect(() => {
    const stepIndex = steps.findIndex((step) => location.href.includes(step));
    if (stepIndex > -1) {
      if (stepIndex === 2 && !applicationUid) {
        setActiveStep(-1);
      } else {
        setActiveStep(stepIndex);
      }
    } else setActiveStep(-1);
  }, [location.href]);

  useEffect(() => {
    if (!applicantUid || (!docIdsString && !state?.application?.docIds)) {
      return;
    }

    const getDocumentsByDocIDs = async (
      docIds: string[],
      applicantUid: string
    ) => {
      if (docIds) {
        return documentService
          .getExtractedDocsByDocIds(docIds, applicantUid)
          .then((response: any) => {
            const resp: any = response?.response;

            const docs = resp?.map((doc: any) => {
              return {
                id: doc?._id,
                ...doc?.documentData,
              };
            });

            if (docs) {
              dispatch({ type: "documents", documents: docs });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    const docIds = docIdsString
      ? docIdsString.split(",")
      : state?.application?.docIds;

    getDocumentsByDocIDs(docIds!, applicantUid);
  }, [docIdsString, state?.application?.docIds]);

  useEffect(() => {
    if (applicationUid) {
      try {
        applicationService
          .getApplicationByUid(applicationUid as string)
          .then((res: any) => {
            if (res) {
              const resp: IApplication = res?.response;
              dispatch({ type: "application", application: resp });
            }
          });
      } catch (error) {
        console.error(error);
      }
    }
  }, [applicationUid]);

  return (
    <ApplicationContext.Provider
      value={{
        activeStep: state.activeStep,
        fromCountry:
          state.fromCountry?.countryISO !== ""
            ? state.fromCountry
            : fromCountry,
        toCountry:
          state.toCountry?.countryISO !== "" ? state.toCountry : toCountry,
        toDate: state.toDate ?? toDate,
        fromDate: state.fromDate ?? fromDate,
        category: state.category ?? category,
        nationality: state.nationality ?? nationality,
        application: state.application,
        applicantUid: state.applicantUid,
        documents: state.documents,
        setActiveStep,
        setFromDate,
        setToDate,
        setCategory,
        setFromCountry,
        setNationality,
        setToCountry,
      }}
      {...props}
    />
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useApplication = () => {
  const context = React.useContext(ApplicationContext);
  if (context === undefined) {
    throw new Error("useApplication() must be used inside ApplicationProvider");
  } else {
    return context;
  }
};
