import React from "react";
import { Card, Text, Badge, Group, Image, Container } from "@mantine/core";
import {
  IconClipboardTextFilled,
  IconPlane,
  IconUser,
} from "@tabler/icons-react";

interface PremiumServiceCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  badgeLabel?: string;
  badgeColor?: string;
  buttonText: string;
  cardBackground?: string;
  cardTextColor?: string;
}

const PremiumServiceCard: React.FC<PremiumServiceCardProps> = ({
  icon,
  title,
  description,
  badgeLabel,
  badgeColor,
  buttonText,
  cardBackground = "rgba(255, 255, 255, 0.5)",
  cardTextColor = "white",
}) => {
  return (
    <Card
      shadow="md"
      padding="lg"
      radius="md"
      style={{
        background: cardBackground,
        backdropFilter: "blur(15px)",
        border: "1px solid rgba(255, 255, 255, 0.2)",
        color: cardTextColor,
        minWidth: "300px",
        borderRadius: "16px",
        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Group gap="apart" style={{ position: "relative" }}>
        {typeof icon === "string" ? (
          <Image src={icon} alt="Icon" width={32} height={32} />
        ) : (
          icon
        )}
        {badgeLabel && (
          <Badge
            color={badgeColor || "blue"}
            variant="filled"
            style={{
              position: "absolute",
              right: 60,
              top: 15,
              transform: "translate(50%, -50%)",
            }}
          >
            {badgeLabel}
          </Badge>
        )}
      </Group>

      <Text fw={600} size="md" mt="md">
        {title}
      </Text>

      <Text color="light" size="xs" mt="xs">
        {description}
      </Text>

      <Text
        variant=""
        gradient={{ from: "orange", to: "red" }}
        color="red"
        mt="md"
        size="md"
      >
        {buttonText}
      </Text>
    </Card>
  );
};

export const PremiumServiceCardGrid: React.FC = () => {
  const serviceData: PremiumServiceCardProps[] = [
    {
      icon: <IconUser />,
      title: "Assign to Visa Staff",
      description:
        "Let a Visa Staff member review and submit your application.",
      badgeLabel: "MOST USED",
      badgeColor: "blue",
      buttonText: "Coming Soon",
      cardBackground: "rgba(255, 255, 255, 0.8)",
      cardTextColor: "black",
    },
    {
      icon: <IconPlane />,
      title: "Get Insurance & Flight",
      description:
        "Get exclusive discounts on travel insurance and flight bookings.",
      buttonText: "Coming Soon",
      cardBackground: "rgba(255, 255, 255, 0.8)",
      cardTextColor: "black",
    },
    {
      icon: <IconClipboardTextFilled />,
      title: "We’ll Write Your Cover Letter",
      description:
        "We’ll draft a professional cover letter for your visa application.",
      badgeLabel: "RECOMMENDED",
      badgeColor: "blue",
      buttonText: "Coming Soon",
      cardBackground: "rgba(255, 255, 255, 0.8)",
      cardTextColor: "black",
    },
  ];

  return (
    <Container fluid style={{ width: "100%", padding: "20px" }}>
      <Group
        gap="xl"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexWrap: "nowrap",
        }}
      >
        {serviceData.map((item, index) => (
          <PremiumServiceCard key={index} {...item} />
        ))}
      </Group>
    </Container>
  );
};
