import apiUtil from "../utils/api.util";

class profileService {
  route = "/user";

  profile = async (id: string, role: string): Promise<any> => {
    const response = await apiUtil.get(`${this.route}/get-profile`, {
      id,
      role,
    });
    return response;
  };
}

export default new profileService();
