import { Box, Container, Text, Title } from "@mantine/core";

interface UserWelcomeSectionProps {
  userName: string;
}

export const UserWelcomeSection = ({ userName }: UserWelcomeSectionProps) => {
  return (
    <div>
      <Title order={3}>Welcome, {userName},</Title>

      <Text size="md">
        Let’s finalize a few details for your visa application.
      </Text>
    </div>
  );
};
