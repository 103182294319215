import { Group, Button, Text, Box, useMantineTheme } from "@mantine/core";
import {
  IconMapPin,
  IconBriefcase,
  IconClock,
  IconCalendar,
} from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";

interface VisaDetailsActionBarProps {
  country: string;
  purpose: string;
  duration: string;
  dateRange: string;
  onChangeClick?: () => void;
  backgroundColor?: string;
  borderColor?: string;
  iconColor?: string;
  textColor?: string;
  dividerColor?: string;
}

export function VisaDetailsActionBar({
  country,
  purpose,
  duration,
  dateRange,
  onChangeClick,
  backgroundColor,
  borderColor = "rgba(0, 0, 0, 0.1)",
  iconColor = "black",
  textColor = "black",
  dividerColor = "#000",
}: VisaDetailsActionBarProps) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const primaryColor = theme.colors[theme.primaryColor][6];
  const iconProps = { size: 16, color: iconColor };
  const textProps = { c: textColor, fw: 500, style: { fontSize: "12px" } };
  const buttonHoverBackground = "rgba(255, 255, 255, 0.1)";

  return (
    <Box
      p="xs"
      style={{
        backgroundColor: backgroundColor,
        borderRadius: "2rem",
        border: `1px solid ${borderColor}`,
        padding: "15px",
        display: "inline-flex",
        alignItems: "center",
        width: isMobile ? "100%" : "auto",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <Group
        wrap="nowrap"
        justify="space-between"
        align="center"
        style={{ width: "100%" }}
      >
        <Group
          wrap="nowrap"
          gap="md"
          align="center"
          style={{ flex: 1, justifyContent: "center" }}
        >
          <Group wrap="nowrap" gap="xs">
            <IconMapPin {...iconProps} />
            {!isMobile && <Text {...textProps}>{country}</Text>}
          </Group>

          <Box
            style={{
              width: "1px",
              height: "24px",
              backgroundColor: dividerColor,
            }}
          />

          <Group wrap="nowrap" gap="xs">
            <IconBriefcase {...iconProps} />
            {!isMobile && <Text {...textProps}>{purpose}</Text>}
          </Group>

          <Box
            style={{
              width: "1px",
              height: "24px",
              backgroundColor: dividerColor,
            }}
          />

          <Group wrap="nowrap" gap="xs">
            <IconClock {...iconProps} />
            {!isMobile && <Text {...textProps}>{duration}</Text>}
          </Group>

          <Box
            style={{
              width: "1px",
              height: "24px",
              backgroundColor: dividerColor,
            }}
          />

          <Group wrap="nowrap" gap="xs">
            <IconCalendar {...iconProps} />
            {!isMobile && <Text {...textProps}>{dateRange}</Text>}
          </Group>
        </Group>

        {/* Button on the right */}
        <Button
          variant="default"
          onClick={onChangeClick}
          styles={{
            root: {
              background: primaryColor,
              backdropFilter: "blur(10px)",
              border: "1px solid white",
              color: "white",
              borderRadius: "20px",
              "&:hover": {
                background: buttonHoverBackground,
              },

              width: "100px",
            },
          }}
        >
          Change
        </Button>
      </Group>
    </Box>
  );
}
