import React, { useState, useCallback } from "react";
import {
  Burger,
  Container,
  Group,
  Avatar,
  Menu,
  UnstyledButton,
  Text,
  Box,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import {
  IconChevronDown,
  IconSettings,
  IconLogout,
  IconUserCircle,
} from "@tabler/icons-react";
import classes from "./AppHeader.module.css";
import cx from "clsx";
import { PremiumServiceCardGrid } from "../premium-service-card/PremiumServiceCard";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../contexts/UserContext";
import { ApplicationProgressStepper } from "../application/ApplicationProgressStepper";
import { useApplication } from "../../../contexts/ApplicationContext";
import { UserWelcomeSection } from "../user-welcome-section/UserWelcomeSection";
import { VisaDetailsActionBar } from "../visaDetailsAction/VisaDetailsActionBar";
import { format, differenceInDays, parse, isValid } from "date-fns";

const links = [
  { link: "/premium-services", label: "Premium Services", disabled: true },
  { link: "/applications", label: "My Applications", disabled: false },
  { link: "/d2c/fileUpload", label: "My Documents", disabled: false },
  { link: "/get-visa", label: "Apply now", disabled: false },
];

const applicationSteps = [
  {
    label: "Guidelines",
    description: "Read the guidelines",
    link: "/visa-requirements",
  },
  {
    label: "Upload",
    description: "Upload your documents",
    link: "/fileUpload",
  },
  {
    label: "Preview",
    description: "Preview your documents",
    link: "/fileUpload/document-playground",
  },
  {
    label: "Submit",
    description: "Submit your application",
    link: "/form",
  },
];

interface AppHeaderProps {
  logoUrl?: string;
}

export function AppHeader({ logoUrl }: AppHeaderProps) {
  const [opened, { toggle }] = useDisclosure(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const [active, setActive] = useState(links[0].link);
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const { logoutCurrentUser, currentUserProfile } = useUser();
  const [showPremiumServices, setShowPremiumServices] = useState(false);
  const user = {
    image: "/path/to/avatar.jpg",
    name: currentUserProfile?.firstName,
  };
  const handleMouseEnter = useCallback(() => setShowPremiumServices(true), []);
  const handleMouseLeave = useCallback(() => setShowPremiumServices(false), []);
  const navigate = useNavigate();
  const {
    activeStep,
    setActiveStep,
    fromDate,
    toDate,
    application,
    category,
    fromCountry,
    toCountry,
  } = useApplication();
  const theme = useMantineTheme();
  const primaryColor = theme.colors[theme.primaryColor][6];
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1024px)");

  const parseDate = (dateStr: string | undefined | Date): Date | null => {
    if (!dateStr) return null;
    if (dateStr instanceof Date) {
      return isValid(dateStr) ? dateStr : null;
    }

    const parsedFromFormat = parse(dateStr, "dd/MM/yyyy", new Date());
    if (isValid(parsedFromFormat)) {
      return parsedFromFormat;
    }

    const dateObj = new Date(dateStr);
    return isValid(dateObj) ? dateObj : null;
  };

  const navItems = links.map((link) => (
    <a
      key={link.label}
      href={link.link}
      className={classes.link}
      data-active={active === link.link || undefined}
      onClick={(event) => {
        event.preventDefault();
        if (link.disabled) return;

        setActiveStep?.(link.link === "/get-visa" ? 0 : -1);
        navigate(link.link);
        setActive(link.link);
      }}
      onMouseEnter={
        link.label === "Premium Services" ? handleMouseEnter : undefined
      }
    >
      {link.label}
    </a>
  ));

  const handleStepClick = useCallback((index: number) => {}, []);

  const HeaderTopWrapper = React.memo(() => (
    <Box
      style={{
        width: "100%",
        padding: "10px 16px",
        borderBottom: "",
      }}
    >
      <Box
        style={{
          width: "90%",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        {/* First Row: User Section & Visa Details */}
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "16px",
          }}
        >
          {/* User Section */}
          {!isMobile ? (
            <Box style={{ flex: 1, minWidth: "200px" }}>
              <UserWelcomeSection userName={user?.name || "-"} />
            </Box>
          ) : null}

          {/* Visa Details Action Bar */}
          <Box
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <VisaDetailsActionBar
              backgroundColor="#f7f7f7"
              country={
                toCountry?.country || application?.visaDetails?.visaTo || ""
              }
              dateRange={`${format(
                parseDate(fromDate ?? application?.visaDetails?.fromDate) ||
                  new Date(),
                "dd/MM/yyyy"
              )} - ${format(
                parseDate(toDate ?? application?.visaDetails?.toDate) ||
                  new Date(),
                "dd/MM/yyyy"
              )}`}
              duration={`${differenceInDays(
                parseDate(toDate ?? application?.visaDetails?.toDate) ||
                  new Date(),
                parseDate(fromDate ?? application?.visaDetails?.fromDate) ||
                  new Date()
              )} days`}
              purpose={category ?? application?.visaDetails?.visaCategory ?? ""}
              onChangeClick={() => {}}
            />
          </Box>
        </Box>

        {/* Second Row: Stepper (UNCHANGED) */}
        <Box
          style={{
            width: isTablet ? "60%" : !isMobile ? "40%" : "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box className={classes.headerTopStepper}>
            <ApplicationProgressStepper
              active={activeStep!}
              handleStepClick={handleStepClick}
              steps={applicationSteps}
              primaryColor={primaryColor}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  ));

  const HeaderTopCenteredWrapper = React.memo(() => (
    <Box className={classes.headerTopCenteredWrapper}>
      <Box className={classes.headerTopContent}>
        <Box className={classes.headerTopCentered}>
          <UserWelcomeSection userName={user?.name || "-"} />
        </Box>
        <Box className={classes.headerTopStepperCentered}>
          <ApplicationProgressStepper
            active={activeStep!}
            handleStepClick={handleStepClick}
            steps={applicationSteps}
          />
        </Box>
      </Box>
    </Box>
  ));
  return (
    <Box>
      <Box className={classes.headerContainer}>
        <div>
          <Box size="xl" className={classes.inner}>
            {/* Logo */}
            <div className={classes.logoContainer}>
              {logoUrl ? (
                <img
                  src={`data:image/png;base64,${logoUrl}`}
                  alt="Logo"
                  className={classes.logo}
                />
              ) : (
                <IconUserCircle size={30} />
              )}
            </div>

            {/* Desktop Menu */}
            <Group gap={5} visibleFrom="md">
              {navItems}
              <div style={{ marginLeft: "1rem" }}>
                <Menu
                  width={260}
                  position="bottom-end"
                  transitionProps={{ transition: "pop-top-right" }}
                  onClose={() => setUserMenuOpened(false)}
                  onOpen={() => setUserMenuOpened(true)}
                  withinPortal
                >
                  <Menu.Target>
                    <UnstyledButton
                      className={cx(classes.user, {
                        [classes.userActive]: userMenuOpened,
                      })}
                    >
                      <Group gap={7}>
                        <Avatar radius="xl" size={30}>
                          {user?.name?.charAt(0)?.toUpperCase()}
                        </Avatar>
                        <Text fw={500} size="sm" lh={1} mr={3}>
                          {user.name}
                        </Text>
                        <IconChevronDown size={12} stroke={1.5} />
                      </Group>
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Label>Settings</Menu.Label>
                    <Menu.Item
                      leftSection={<IconSettings size={16} stroke={1.5} />}
                      onClick={() => navigate("/profile")}
                    >
                      Account settings
                    </Menu.Item>
                    <Menu.Item
                      leftSection={<IconLogout size={16} stroke={1.5} />}
                      onClick={logoutCurrentUser}
                    >
                      Logout
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </div>
            </Group>

            {/* Burger (Mobile Menu Button) */}
            <Burger
              opened={menuOpened}
              onClick={() => setMenuOpened((prev) => !prev)}
              hiddenFrom="md"
              size="sm"
            />
          </Box>

          {/* Mobile Menu */}
          {menuOpened && (
            <Box className={classes.mobileMenu} hiddenFrom="md">
              <Group gap="xs" style={{ direction: "initial" }}>
                {navItems}
              </Group>
            </Box>
          )}
        </div>

        {showPremiumServices && (
          <Box className={classes.premiumServiceCardGrid}>
            <PremiumServiceCardGrid />
          </Box>
        )}
      </Box>

      {activeStep !== -1 && (
        <>
          {active === "/get-visa" && activeStep === 0 ? (
            <HeaderTopCenteredWrapper />
          ) : (
            (application ||
              (fromDate && toDate && category && fromCountry && toCountry)) && (
              <HeaderTopWrapper />
            )
          )}
        </>
      )}
    </Box>
  );
}
