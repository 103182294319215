export const SUPPORTED_DOCS = [
  "Aadhar Card",
  "Passport",
  "Pancard",
  "Electricity Bill",
  "Tax Challan",
  "Invoices",
  "Bill Of Entry",
  "Certificate of incorporation",
  "Proof Of funds",
  "Hotel Bill",
  "Food Bill",
  "Travel Bill",
  "Ration Card",
  "Income Application Form",
  "EPIC Card",
  "Study Certificate",
  "Study Exam Hall Ticket",
  "Birth Certificate",
  "Bonafide Certificate",
  "DOB & Community Application Form",
];

export const ROLES = {
  ADMIN: "admin",
  AGENCY: "agency",
  AGENT: "agent",
  APPLICANT: "applicant",
  REVIEWER: "reviewer",
  DISTRIBUTOR: "distributor",
};

export const FORM_STATUS = {
  DRAFTED: "DRAFTED",
  SUBMITTED: "SUBMITTED",
};

export const APPLICATION_STATUS = {
  DRAFT: "DRAFT",
  DRAFT_UES: "DRAFT - User Email Sent",
  DRAFT_URR: "DRAFT - User Response Received",
  DRAFT_SFR: "DRAFT - Sent for Review",
  DRAFT_RRR: "DRAFT - Review Response Received",
  PACKAGE: "Package Creation In Progress",
  ERROR_INC_INFO: "Error - Incomplete Info",
  COMPLETED: "Package ready",
  DATA_COLLECTED: "Form-data collected",
  STARTED: "Online form started",
  FINISHED: "Online form completed",
  CONFIRMED: "Application submitted",
};

export const STATUS = {
  ACTIVE: "ACTIVE",
  DISABLED: "DISABLED",
};

export const AGENCY_TYPES = {
  D2C: "D2C",
  B2B: "B2B",
  B2B2C: "B2B2C",
};

export const APPLICANT_TYPES = {
  D2C: "D2C",
  B2B: "B2B",
};

export const AGENCY_SCOPES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  DISTRIBUTOR: "DISTRIBUTOR",
};

export const REVIEWER_SCOPES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  AGENCY: "AGENCY",
};

export const DISTRIBUTOR_SCOPES = {
  SUPER_ADMIN: "SUPER_ADMIN",
};

export const REVIEW_TYPES = {
  REVIEW: "REVIEW",
  QUERY: "QUERY",
};
